import React from "react";
import "./homepage.css";

const HomePage = () => {
  return (
    <div className="homepage">
      <main>
        <section className="hero">
          <div className="hero-content">
            <h1>Welcome to RoseBird</h1>
            <p>
              We're preparing for something big! Our website is temporarily down
              as we get ready to unveil exciting updates.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default HomePage;
