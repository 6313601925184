import React from "react";
import "./header.scss";
import logo from "../../images/logo.png";

const Header = () => {
  return (
    <header className="header">
      <img className="img" src={logo} alt="rosebird" />
    </header>
  );
};

export default Header;
